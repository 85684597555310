<template>
    <div>
        <b-modal 
            v-model="modalShow" 
            id="modal-email-test-md" 
            size="md" 
            title="Email BFPA Test Report"
            @ok="handleOk"
        
        >
            <b-row v-if="awaitingFetch">
                <b-col class="text-center">
                    <b-spinner variant="success" label="Spinning"></b-spinner><br>
                    {{waitMessage}}
                </b-col>
            </b-row>
            <div v-else>
                <b-row>
                    <b-col>
                        Who should receive this email. (Recipients cannot see each other) <hr>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.utility.length > 0">
                    <b-col>
                        Utility Contact Email(s)
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.utility.length > 0">
                    <b-col>
                        <b-form-checkbox-group
                            v-model="selected.utility"
                            :options="emailOptions.utility"
                            switches
                            stacked
                            size="lg"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.utility.length > 0">
                    <b-col>
                        <hr>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.billing.length > 0">
                    <b-col>
                        Billing Contact Email(s)
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.billing.length > 0">
                   <b-col>
                        <b-form-checkbox-group
                            v-model="selected.billing"
                            :options="emailOptions.billing"
                            switches
                            stacked
                            size="lg"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.billing.length > 0">
                    <b-col>
                        <hr>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.site.length > 0">
                    <b-col>
                        Site Contact Email(s)
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.site.length > 0">
                    <b-col>
                        <b-form-checkbox-group
                            v-model="selected.site"
                            :options="emailOptions.site"
                            switches
                            stacked
                            size="lg"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.site.length > 0">
                    <b-col>
                        <hr>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.other.length > 0">
                    <b-col>
                        Other Email(s)
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.other.length > 0">
                    <b-col>
                        <b-form-checkbox-group
                            v-model="selected.other"
                            :options="emailOptions.other"
                            switches
                            stacked
                            size="lg"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row v-if="emailOptions.other.length > 0">
                    <b-col>
                        <email-input
                            :dataName="emailTextInput.dataName"
                            :inputLabel="emailTextInput.label"
                            :parentFormID="ownUniqueID"
                        ></email-input>
                    </b-col>
                </b-row>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="cancel()" variant="secondary" :disabled="waitMessage == 'Sending Emails'">Cancel</b-button>
                <b-button @click="ok()" variant="primary" :disabled="(waitMessage == 'Sending Emails')">Send</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const _ = require('underscore');

import EmailInput from '@/components/FormEmailInput.vue';


export default {
    name: 'emailtestreport',
    components:{
        'email-input': EmailInput,
    },
    props: {
        eventID: {
            type: String,
            default: () =>{ return null; }
        },
        requestOpen:{
            type: Number,
            default: ()=>{ return 0; }
        },
        markSent:{
            type: Function,
            default: () =>{ return null; }
        },
        dontMarkSent:{
            type: Boolean,
            default: () =>{ return false; }
        }
    },
    data(){
        return{ 
            ownUniqueID: uuidv4(),
            awaitingFetch: true,
            waitMessage: 'Loading Available Recipients',
            modalShow: false,
            emailOptions:{
                utility: [],
                billing: [],
                site: [],
                other: []
            },
            selected:{
                utility: [],
                billing: [],
                site: [],
                other: []
            },
            emailTextInput:{
                dataName: 'additonalOtherEmail',
                label: 'Custom Recipient',
            }
        }
    },
    methods: {
        handleOk(evt){
            evt.preventDefault();
            var value = [];
            var form = this.$store.getters.getForm(this.ownUniqueID);
            console.log(form)
            if(_.has(form,'additonalOtherEmail')){
                if(form.additonalOtherEmail.value != "" && form.additonalOtherEmail.value != null){
                    value = form.additonalOtherEmail.value;
                }
            }
            var values = _.flatten([this.selected.utility, this.selected.billing, this.selected.site, this.selected.other, value]);
            if(values.length > 0){
                this.requestEmail();
            }else{
                this.makeToast("No Available Recipients","Unable to send emails, no recipients selected or entered", 'warning')
            }
            
            
        },
        showModal(){
            if(this.eventID != null){
                this.modalShow = true;
                this.init();
            }else{
                this.modalShow = false;
            }
            
        },
        init(){
            this.$store.commit('initForm',{ localFormID: this.ownUniqueID, meta: null});
            this.waitMessage = 'Loading Available Recipients';
            this.awaitingFetch = true;
            this.emailOptions ={
                utility: [],
                billing: [],
                site: [],
                other: []
            };
            this.selected = {
                utility: [],
                billing: [],
                site: [],
                other: []
            };
            instance.get(process.env.VUE_APP_API_BASE_URL + "/atils/email/recipients/" + this.eventID)
            .then((resp)=>{
                var emails = resp.data.result;

                this.emailOptions.utility = emails.utility_email || [];
                this.emailOptions.billing = emails.billing_email || [];
                this.emailOptions.site = emails.site_contact_email || [];
                if(emails.current_user_email != null){
                    this.emailOptions.other.push(emails.current_user_email);
                } 
                this.awaitingFetch = false;

            })
            .catch((err)=>{
                this.makeToast("Failed Loading Available Recipients", "Failed while fetching the possible recipients for this email", 'danger');
                console.log(err);
                this.awaitingFetch = false;
            })
        },
        requestEmail(){
            var value = [];
            var form = this.$store.getters.getForm(this.ownUniqueID);
            console.log(form)
            if(_.has(form,'additonalOtherEmail')){
                if(form.additonalOtherEmail.value != "" && form.additonalOtherEmail.value != null){
                    value = form.additonalOtherEmail.value;
                }
            }
            var sendTo = _.flatten([this.selected.utility, this.selected.billing, this.selected.site, this.selected.other, value]);
            var emailRequest = {
                to: sendTo,
                eventID: this.eventID
            };
            this.waitMessage = 'Sending Emails';
            this.awaitingFetch = true;
            instance.post(process.env.VUE_APP_API_BASE_URL + "/email/send/test.json", emailRequest)
            .then((response)=>{
                console.log(response.data.result);
                console.log(this.markSent);
                if(this.dontMarkSent){
                    this.awaitingFetch = false;
                    this.modalShow = false;
                }else{
                    this.markSent((sentRes)=>{
                    if(sentRes == 'ok'){
                        this.awaitingFetch = false;
                        this.modalShow = false;
                    }else{
                        this.makeToast("Failed To Mark Work Order Sent", "Retrying...", 'warning');
                        this.markSent((sentRes)=>{
                            if(sentRes == 'ok'){
                                this.awaitingFetch = false;
                                this.modalShow = false;
                            }else{
                                this.makeToast("Retry Failed", "Failed to mark work order sent", 'danger');
                                this.awaitingFetch = false;
                                this.modalShow = false;
                            }
                        })
                    }
                })
                }
                
                
            })
            .catch((err)=>{
                this.makeToast("Failed While Sending Email", "Something went wrong while sending the requested email(s)", 'danger');
                console.log(err);
                this.awaitingFetch = false;
                this.modalShow = false;
            })
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        }
    },
    watch: { 
        requestOpen: function (newVal, oldVal){
            if(newVal != null){
                this.showModal();
            }else{
                // Its Null Do Nothing
            }
        }
    },
    computed: {
        selectedEmails: function(){
            var value = [];
            var form = this.$store.getters.getForm(this.ownUniqueID);
            console.log(form)
            if(_.has(form,'additonalOtherEmail')){
                if(form.additonalOtherEmail.value != "" && form.additonalOtherEmail.value != null){
                    value = form.additonalOtherEmail.value;
                }
            }
            return _.flatten([this.selected.utility, this.selected.billing, this.selected.site, this.selected.other, value]);
        }
    },
    beforeCreate(){
    },
    beforeMount (){
    },
}
</script>

<style scoped>
</style>