<template>
    <b-button 
        class='material-icons modal-btn' 
        variant="success" 
        @click="touch()"
        :size="size"
    >
        add_circle_outline
    </b-button>
</template>

<script>
export default {
    name: 'arrayengineaddbutton',
    props:{
        touch: Function,
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    }
}
</script>

<style>

</style>

